import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectsTableConfig } from '@constants/projectsTableConfig.const';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { TableComponent } from '@shared/components/table/table.component';
import { TableActions, TableSignals } from '@shared/interfaces/table';
import { ButtonModule } from 'primeng/button';
import { ProjectDetailsResponse } from '@shared/interfaces';
import { Subscription, take } from 'rxjs';
import { PermissionService } from '@Services/permission/permission.service';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { CommonModule } from '@angular/common';
import { BackButtonComponent } from '@shared/components/back-button/back-button.component';
import { ProjectListingMode } from './constants';
import { ArchiveDialogService } from '@Services/dialogs/archive-dialog/archive-dialog.service';
import { ProjectsArchiveTableConfig } from '@constants/projectsArchiveTableConfig.const';
import { DeleteDialogService } from '@Services/dialogs/delete-dialog/delete-dialog.service';
import { FileUploadModule } from 'primeng/fileupload';
import { FileService } from '@Services/file/file.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { DownloadTemplates } from '@shared/enums/common/common';
import { DownloadTemplateOverlayComponent } from '@shared/components/download-template-overlay/download-template-overlay.component';
import { ProjectService } from '@features/project/services/project/project.service';
import { NoItemsInfoComponent } from '@shared/components/no-items-info/no-items-info.component';

/**
 * Component for displaying a list of projects.
 */
@Component({
  selector: 'app-project-list',
  standalone: true,
  imports: [
    PageTitleComponent,
    ButtonModule,
    TableComponent,
    CommonModule,
    BackButtonComponent,
    FileUploadModule,
    DownloadTemplateOverlayComponent,
    NoItemsInfoComponent,
  ],
  templateUrl: './project-list.component.html',
  styleUrl: './project-list.component.scss',
})
export class ProjectListComponent implements OnDestroy {
  @ViewChild(TableComponent) projectTableComponent!: TableComponent<ProjectDetailsResponse>;

  projectsTableConfig = ProjectsTableConfig;

  subscription: Subscription;

  mode = ProjectListingMode.UNARCHIVE;

  ProjectListingMode = ProjectListingMode;

  downloadTemplate = DownloadTemplates.PROJECT;

  constructor(
    private readonly router: Router,
    public readonly permissionService: PermissionService,
    private readonly archiveDialogService: ArchiveDialogService,
    private readonly deleteDialogService: DeleteDialogService,
    private readonly fileService: FileService,
    private readonly toastMessageService: ToastMessageService,
    private readonly projectService: ProjectService,
  ) {
    this.subscription = new Subscription();
  }

  /**
   * Navigates to the create project page.
   */
  addProject() {
    this.router.navigate(['/project/create']);
  }

  /**
   * Handles the signals emitted by the table component.
   * @param event The table signals event.
   */
  handleTableSignals(event: TableSignals<ProjectDetailsResponse>) {
    const data = event.data;
    if (event.action === TableActions.viewProjectDetails) {
      this.router.navigate([`/project/${data.id}`]);
    } else if (event.action === TableActions.openEditMode) {
      this.router.navigate([`/project/${data.id}/edit`]);
    } else if (event.action == TableActions.deleteItem) {
      this.openProjectDeleteDialog(data);
    } else if (event.action === TableActions.archive) {
      this.archiveDialogService.openArchiveDialog(
        {
          headerText: 'Archive Project',
          name: data.name,
          description: 'Please provide a reason for archiving project',
          path: `assetmanagement/projects/${data.id}/archive/`,
        },
        () => {
          // success callback  to refresh the table
          this.projectTableComponent.getTableData();
        },
      );
    }
  }

  /**
   * Opens the delete project dialog.
   * @param projectDetails The project details.
   */
  openProjectDeleteDialog(projectDetails: ProjectDetailsResponse) {
    this.deleteDialogService.openDeleteDialog(
      {
        headerText: 'Delete Project',
        name: projectDetails.name,
        description: 'Please provide a reason for deleting project',
        path: `assetmanagement/projects/${projectDetails.id}/`,
      },
      'project',
      () => {
        this.projectTableComponent.reloadCurrentPage();
      },
    );
  }

  onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.projectService
        .importProjectsByXLSX(file, file.name)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.toastMessageService.showSuccessToast('Project(s) imported successfully');
            this.projectTableComponent.reloadCurrentPage();
            input.files = null;
            input.value = '';
          },
          error: (error: HttpErrorResponse) => {
            this.toastMessageService.showValidationErrors(error.error);
            input.files = null;
            input.value = '';
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Checks if the user has the permission to view projects.
   * @returns True if the user has the permission, false otherwise.
   */
  get hasViewProjectPermission(): boolean {
    return this.permissionService.hasPermission(AllGroupPermissions.VIEW_PROJECT);
  }

  /**
   * Checks if the user has the permission to create projects.
   * @returns True if the user has the permission, false otherwise.
   */
  get hasCreateProjectPermission(): boolean {
    return this.permissionService.hasPermission(AllGroupPermissions.ADD_PROJECT);
  }

  /**
   * Toggles the listing mode between unarchived and archived projects.
   */
  toggleListingMode() {
    if (this.mode === ProjectListingMode.UNARCHIVE) {
      this.mode = ProjectListingMode.ARCHIVED;
      this.projectsTableConfig = ProjectsArchiveTableConfig;
    } else {
      this.mode = ProjectListingMode.UNARCHIVE;
      this.projectsTableConfig = ProjectsTableConfig;
    }
  }

  /**
   * Gets the page title based on the listing mode.
   * @returns The page title.
   */
  get pageTitle(): string {
    return this.mode === ProjectListingMode.UNARCHIVE ? 'Projects' : 'Archived Projects';
  }

  get hasNoItemsOnFilter(): boolean {
    if (
      this.projectTableComponent.filtersData &&
      Object.keys(this.projectTableComponent.filtersData).length > 0
    ) {
      return true;
    }
    return false;
  }

  downloadFile() {
    this.fileService
      .getImportTemplate(this.downloadTemplate)
      .then(() => {
        console.info('Template downloaded successfully');
      })
      .catch((error) => {
        console.error('Error downloading template:', error);
      });
  }
}
