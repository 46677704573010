import { Component, HostListener, OnDestroy } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { LayoutComponent } from './core/layout/layout/layout.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { Subscription } from 'rxjs';
import { ToastMessage } from '@shared/interfaces/toast-message.interface';
import { LoaderComponent } from '@shared/components/loader/loader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, RouterOutlet, LayoutComponent, ToastModule, LoaderComponent],
  templateUrl: './app.component.html',
  providers: [MessageService],
})
export class AppComponent implements OnDestroy {
  title = 'asset-management';

  toastSubscription: Subscription;

  /*
   * Prevents input with type number to update value on wheel event
   */
  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    const activeElement = document.activeElement as HTMLInputElement;

    // Only prevent scroll if the focused element is a number input
    if (activeElement?.type === 'number' && activeElement === event.target) {
      activeElement.blur(); // Temporarily removes focus to prevent value change
      setTimeout(() => activeElement.focus(), 0); // Restores focus immediately
    }
  }

  constructor(
    private toastMessageService: ToastMessageService,
    private messageService: MessageService,
  ) {
    this.toastSubscription = this.toastMessageService.getTostObserver().subscribe({
      next: (data: ToastMessage) => {
        this.messageService.add({
          severity: data.severity,
          detail: data.details,
          summary: data.summary,
        });
      },
    });
  }
  ngOnDestroy(): void {
    this.toastSubscription.unsubscribe();
  }
}
