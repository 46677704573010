<div class="tabs">
  <ul class="nav nav-tabs d-flex flex-wrap">
    @for (tab of navigationTabs; track tab; let i = $index) {
      @if (!tab.permission || (tab.permission && checkVisibilityByPermission(tab.permission))) {
        <li class="nav-item" (click)="selectTab(tab.key)" (keyup.enter)="keyup()" [tabindex]="i">
          <a
            [routerLink]="tab.routerLink"
            class="nav-link"
            [class.active]="selectedTab === tab.key"
          >
            <div class="d-flex justify-content-center align-items-center g-8">
              @if (tab.icon) {
                <div><span [class]="tab.icon"></span></div>
              } @else if (tab.iconImage) {
                <img [ngStyle]="tab.imageStyle" [src]="tab.iconImage" alt="tab.title" />
              }
              <div>{{ tab.title }}</div>
            </div></a
          >
        </li>
      }
    }
  </ul>
</div>
