<div class="margin-side-bar">
  <div class="header-container">
    <div class="back-button-container mt-20">
      <app-back-button />
    </div>

    <div class="mb-20 mt-10 d-flex justify-content-space-between">
      <app-page-title
        [title]="(projectDetails && projectDetails.name) || 'Project'"
        fontWeight="700"
      />
      <div>
        <p-button
          class="primary-color export-project-details"
          type="button"
          [outlined]="true"
          icon="pi pi-download"
          size="small"
          [link]="true"
          (click)="exportProjectDetailsCSV()"
          (keyup.enter)="exportProjectDetailsCSV()"
          tabindex="0"
          [loading]="exportProjectInProgress"
          pTooltip="Export project details"
          tooltipPosition="left"
        />
      </div>
    </div>
    @if (projectDetails) {
      <div class="d-flex justify-content-start g-8 mb-10">
        <div>
          {{ projectDetails && projectDetails.code }}
        </div>
        <div class="border-left-blue">Capacity AC {{ projectDetails.capacity_ac }} MW</div>
        <div class="border-left-blue">{{ projectDetails.portfolio }}</div>
        @if (siteCode) {
          <div class="border-left-blue">{{ siteCode }}</div>
        }
      </div>
      <div class="widgets-container">
        <div>
          <!-- stats div -->
        </div>
        <app-weather-widget
          [coordinates]="projectDetails.coordinates"
          [appId]="secretKeys && secretKeys.weather"
        ></app-weather-widget>
        <div>
          <!-- location div -->
          <app-location-widget
            [cityName]="projectDetails.city.name"
            [countryName]="projectDetails.country.name"
            [appId]="secretKeys && secretKeys.location"
            [coordinates]="projectDetails.coordinates"
          >
          </app-location-widget>
        </div>
      </div>
    }
  </div>
  <div class="mb-20">
    <app-navigation-tabs />
  </div>
  <router-outlet></router-outlet>
</div>
