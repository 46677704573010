<div class="project-list-container margin-side-bar mt-20">
  @if (mode === ProjectListingMode.ARCHIVED) {
    <app-back-button [emitEvent]="true" (emitBackClick)="toggleListingMode()" />
  }
  <div
    class="header-container d-flex justify-content-space-between align-items-end title-container"
  >
    <div class="d-flex g-13">
      <app-page-title [title]="pageTitle" fontWeight="700" />
      @if (mode === ProjectListingMode.UNARCHIVE) {
        <p-button
          (click)="toggleListingMode()"
          (keyup.enter)="toggleListingMode()"
          class="view-archive-button"
          [text]="true"
          size="small"
          type="button"
          [style]="{ 'padding': '5px' }"
          tabindex="0"
        >
          <div class="d-flex g-8 align-items-center">
            <img src="assets/icons/archive-icon-samll.svg" alt="list" height="25px" />
            <app-page-title title="View Archive" [size]="14" />
          </div>
        </p-button>
      }
    </div>
    @if (mode === ProjectListingMode.UNARCHIVE) {
      <div *ngIf="hasCreateProjectPermission" class="d-flex align-items-center g-13 mt-20">
        <app-download-template-overlay [downloadTemplate]="downloadTemplate" />
        <div>
          <p-button
            class="primary-color upload-tasks"
            type="button"
            label=""
            [outlined]="true"
            size="small"
            (click)="fileInput.click()"
            (keyup.enter)="fileInput.click()"
            tabindex="0"
          >
            <div class="d-flex g-8 align-items-center">
              <img src="assets/icons/file-import.svg" alt="list" height="25px" />
              <app-page-title title="Upload Projects" [size]="14" />
            </div>
          </p-button>
          <input
            #fileInput
            type="file"
            (change)="onFileSelect($event)"
            accept=".xls,.xlsx"
            hidden
          />
        </div>
        <p-button
          class="primary-color add-project"
          type="button"
          icon="pi pi-plus"
          label="Add Project"
          [outlined]="true"
          size="small"
          (click)="addProject()"
          (keyup.enter)="addProject()"
          tabindex="0"
        />
      </div>
    }
  </div>
  <div *ngIf="hasViewProjectPermission">
    @let subHeadings =
      [
        "It looks like you haven't added any projects yet.",
        'To get started, click "Add Project" to create a new project or',
        '"Upload Projects" to import your existing list.',
      ];
    @let noFilterMessageWithPermissionForArchive =
      mode === ProjectListingMode.UNARCHIVE
        ? 'There are no projects added'
        : 'There are no archived projects';
    @let heading = mode === ProjectListingMode.UNARCHIVE ? 'No Projects' : 'No Archived Projects';
    <app-table [tableConfig]="projectsTableConfig" (tableSignals)="handleTableSignals($event)">
      <ng-template #noRecordFoundTemplate>
        <app-no-items-info
          [emptyInfoTemplate]="'Advance'"
          [heading]="heading"
          [subHeadings]="subHeadings"
          [noFilterItemMessage]="'No search results found with these filters.'"
          [noFilterMessageWithoutPermission]="noFilterMessageWithPermissionForArchive"
          [hasCreatePermission]="
            hasCreateProjectPermission && mode === ProjectListingMode.UNARCHIVE
          "
          [isGlobalListing]="false"
          [hasNoItemsOnFilter]="hasNoItemsOnFilter"
          [addButtonLabel]="'Add Project'"
          [uploadButtonLabel]="'Upload Projects'"
          [hasUploadOption]="true"
          emptyListImagePath="assets/icons/projects-primary.svg"
          (addClickHandler)="addProject()"
          (downloadTemplateClickHandler)="downloadFile()"
          (onFileSelectHandler)="onFileSelect($event)"
        />
      </ng-template>
    </app-table>
  </div>
</div>
