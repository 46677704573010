import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { PrimeIcons } from 'primeng/api';

export enum ProjectTabKeys {
  GENERAL = 'general',
  TASKS = 'tasks',
  CLAIMS = 'claims',
  INVOICES = 'invoices',
  PERFORMANCE = 'performance',
  ASSET_TREE = 'asset_tree',
  OFFTAKER = 'offtaker',
  INTEGRATION = 'integration',
}

const COMMON_IMAGE_STYLE = { height: '18px' };

export const NAVIGATION_TABS = [
  {
    title: 'General',
    icon: null,
    routerLink: './',
    key: ProjectTabKeys.GENERAL,
    permission: AllGroupPermissions.VIEW_PROJECT,
    iconImage: 'assets/icons/projects-primary.svg',
    imageStyle: { height: '20px' },
  },
  {
    title: 'Tasks',
    icon: null,
    routerLink: './task',
    key: ProjectTabKeys.TASKS,
    permission: AllGroupPermissions.VIEW_TASK,
    iconImage: 'assets/icons/tasks-primary.svg',
    imageStyle: { ...COMMON_IMAGE_STYLE },
  },
  {
    title: 'Claims',
    icon: null,
    routerLink: './claim',
    key: ProjectTabKeys.CLAIMS,
    permission: AllGroupPermissions.VIEW_CLAIM,
    iconImage: 'assets/icons/claims-primary.svg',
    imageStyle: { ...COMMON_IMAGE_STYLE },
  },
  {
    title: 'Invoices',
    icon: null,
    routerLink: './invoice',
    permission: AllGroupPermissions.VIEW_INVOICE,
    key: ProjectTabKeys.INVOICES,
    iconImage: 'assets/icons/invoices-primary.svg',
    imageStyle: { ...COMMON_IMAGE_STYLE, height: '20px' },
  },
  {
    title: 'Technical Performance',
    icon: PrimeIcons.CHART_LINE,
    routerLink: './performance',
    key: ProjectTabKeys.PERFORMANCE,
    permission: AllGroupPermissions.VIEW_PROJECT_PERFORMANCE,
    iconImage: null,
  },
  {
    title: 'Asset Tree',
    icon: null,
    routerLink: './asset-tree',
    key: ProjectTabKeys.ASSET_TREE,
    permission: AllGroupPermissions.VIEW_EQUIPMENT,
    iconImage: 'assets/icons/assets-primary.svg',
    imageStyle: { ...COMMON_IMAGE_STYLE },
  },
  {
    title: 'Offtakers',
    icon: null,
    routerLink: './offtaker',
    key: ProjectTabKeys.OFFTAKER,
    permission: AllGroupPermissions.VIEW_PPA_CONTRACT,
    iconImage: 'assets/icons/offtakers-primary.svg',
    imageStyle: { ...COMMON_IMAGE_STYLE, height: '20px' },
  },
  {
    title: 'Integration',
    icon: null,
    routerLink: './integration',
    key: ProjectTabKeys.INTEGRATION,
    permission: AllGroupPermissions.INTEGRATION_VIEW_DATA,
    iconImage: 'assets/icons/sync-primary.svg',
    imageStyle: { ...COMMON_IMAGE_STYLE },
  },
];
